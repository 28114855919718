
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import { ValidationObserver } from 'vee-validate';
  import moment, { Moment } from 'moment';
  import { State } from '@/models/State';
  import { Backup, DataContainer, DataContainerStatus } from '@/models/Common';
  import Modal from '@/components/common/Modal.vue';
  import FormInput from '@/components/common/form-elements/FormInput.vue';

  @Component({
    components: {
      Modal,
      ValidationObserver,
      FormInput,
    },
  })
  export default class TabRestoreBackup extends Vue {
    @StateClass settings!: State['settings'];
    @StateClass backups!: State['backups'];
    @StateClass restoreBackup!: State['restoreBackup'];
    @StateClass restoreBackupStatus!: State['restoreBackupStatus'];
    @Getter getBackups!: Backup[];
    @Action fetchBackups!: Function;
    @Action startRestoreBackup!: Function;
    @Action fetchRestoreBackupStatus!: Function;
    @Action submitGeneralSettings!: Function;

    maxRestoreDate: (number | string)[] = [3, 'months'];
    showModal: boolean = false;
    isDoneRestoring: boolean = false;
    selectedRestorationDate: string = '';

    restoreBackupStatusInterval: any = null

    columns: string[] = ['date']

    options = {
      headings: {
        date: 'Date',
      },
      orderBy: {
        column: 'date',
        ascending: false,
      },
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        date: 'table__col--date ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed',
    };

    beforeMount(): void {
      window.addEventListener('beforeunload', this.preventNavigation);
    }

    mounted(): void {
      this.fetchBackups();
    }

    beforeDestroy(): void {
      window.removeEventListener('beforeunload', this.preventNavigation);
    }

    @Watch('restoreBackup.status')
    onRestoreBackupChange(newStatus) {
      if (newStatus === DataContainerStatus.Success) {
        this.handleRestoreInProgress();
      }
      if (newStatus === DataContainerStatus.Error) {
        this.submitGeneralSettings({
          maintenance: false,
          bloqadminMaintenance: false,
          restoring: false,
        });
      }
    }

    @Watch('restoreBackupStatus.payload')
    onRestoreBackupStatusChange(newPayload) {
      if (newPayload?.done) {
        this.handleRestoreDone();
      }
    }

    handleRestore(): void {
      this.isDoneRestoring = false;
      this.startRestoreBackup({ date: this.selectedRestorationDate });
      this.submitGeneralSettings({
        maintenance: true,
        bloqadminMaintenance: true,
        restoring: true,
      });
    }

    handleRestoreInProgress(): void {
      this.fetchRestoreBackupStatus();

      this.restoreBackupStatusInterval = setInterval(
        (): void => { this.fetchRestoreBackupStatus(); },
        5000,
      );
    }

    handleRestoreDone(): void {
      this.isDoneRestoring = true;
      clearInterval(this.restoreBackupStatusInterval);
      this.submitGeneralSettings({
        maintenance: false,
        bloqadminMaintenance: false,
        restoring: false,
      });
    }

    handleModalOpen(date: string): void {
      this.showModal = true;
      this.selectedRestorationDate = date;
    }

    handleModalClose(): void {
      if (!this.isRestoring) {
        this.showModal = false;
        this.isDoneRestoring = false;
      }
    }

    preventNavigation(event: any): void {
      if (this.isRestoring) {
        event.preventDefault();
        event.returnValue = '';
      }
    }

    getPercentage(amount, outOf) {
      return (amount * 100) / outOf;
    }

    get isRestoring(): boolean {
      return !!this.settings?.restoring;
    }

    get loading(): boolean {
      return this.backups.status === DataContainerStatus.Processing;
    }

    get activeRestoreStatusAmount(): number | null {
      return this.getPercentage(
        this.restoreBackupStatus.payload?.progressBytes?.completedWork,
        this.restoreBackupStatus.payload?.progressBytes?.estimatedWork,
      ) || null;
    }
  }
